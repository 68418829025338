import { Languages } from './../../../core/services/user-settings.service';
import { PERMISSIONS, PermissionService } from 'src/app/core/services/permission.service';
import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { routeFadeStateTrigger } from '../authentication.animations';
import { AuthService } from '../../../core/services/auth.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiValidatorService } from '../../../core/services/api-validator.service';
import { FormController } from '../../../classes/form-controller.class';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  animations: [routeFadeStateTrigger],
})
export class LoginComponent extends FormController {

  @HostBinding('@routeFadeState') true;

  showCustomMessage = false;
  customMessage = '';

  constructor(private authService: AuthService,
              private fB: UntypedFormBuilder,
              private translateService: TranslateService,
              aV: ApiValidatorService,
              private configService: ConfigService,
              private permissionService: PermissionService,
              private router: Router) {
    super(aV);
    this.translateService.setDefaultLang(Languages.PL);
  }

  runOnInit() {
    this.authService.logout(false);
  }

  buildForm() {
    this.form = this.fB.group({
      username: ['', [
        Validators.required
      ]],
      password: ['', Validators.required],
    });
  }

  processSubmit(formValues: any) {
    this.authService.login(formValues)
      .subscribe(
        (response: Response) => {
          if (this.authService.loggedIn()) {
            // connected boat
            const defaultRedirect = this.configService.isCbTheme() ?
              '/map/boats' :
              this.permissionService.hasPermission(PERMISSIONS.RIDES_NODATA) ? '/work/employees' : '/';
            const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : defaultRedirect;
            this.authService.redirectUrl = null;
            this.formPending = false;
            this.router.navigate([redirect]);
          }
        }, (err) => {
          console.log(err);
          if (err.status === 428) {
            this.customMessage = this.translateService.instant('PASSWORD_RESET_REQUIRED');
            this.showCustomMessage = true;
          } else {
            this.errorHandler(err);
          }
        }
      );
  }
}
