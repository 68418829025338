<div class="v-authentication__view">
    <div class="mb-xxl">
        <app-logo width="100%"></app-logo>
    </div>
    <h1 class="headline  text-center">{{MSG?.HERO}}</h1>

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div [ngSwitch]="isSend">
            <p *ngSwitchCase="true" class="mb-5">{{MSG?.SUCCESS}}</p>
            <div *ngSwitchDefault>
                <div class="my-l">
                    <app-form-group>
                        <label for="new_password1">Nowe hasło</label>
                        <input name="new_password1"
                               id="password1"
                               type="password"
                               formControlName="password"
                               app-form
                        >
                        <app-error-message [control]="form.controls['password']"
                                           [apiError]="formErrors?.password"></app-error-message>
                    </app-form-group>
                </div>
                <div>
                    <app-form-group>
                        <label for="new_password1">Potwierdź hasło</label>
                        <input name="new_password2"
                               id="password2"
                               type="password"
                               formControlName="password2"
                               app-form
                        >
                        <app-error-message [control]="form.controls['password2']"
                                           [apiError]="formErrors?.password2"></app-error-message>
                    </app-form-group>
                </div>

            </div>
        </div>
        <app-error-message [control]="form" [apiError]="formErrors"></app-error-message>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-4">
            <div>
                <button class="btn btn-primary w-100" [disabled]="formPending || isSend">
                    <app-spinner [showSpinner]="formPending" color="white"></app-spinner>
                    <i class="i-check"></i> Zapisz hasło
                </button>
            </div>
            <div class="u--text-13 u--content-block mt-l  text-center">
              <a routerLink="/login" class="u--underline btn btn-link">Zaloguj</a>
          </div>
        </div>

    </form>
</div>
